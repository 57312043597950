import React, { memo } from 'react';

import AddAllItemsErrorModal from 'components/AddAllItemsErrorModal';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';

import styles from './AddAllItemsButton.scss';

export interface AddAllItemsButtonProps {
  addAllItems: () => void;
  display?: boolean;
  itemCount?: number;
  label?: string;
  onTogglePreviousOrderNotesModal?: () => void;
}

const AddAllItemsButton = memo(
  ({
    addAllItems,
    display = true,
    itemCount,
    label,
    onTogglePreviousOrderNotesModal,
  }: AddAllItemsButtonProps) => {
    if (!display || itemCount === 0) return null;

    return (
      <>
        <div className="row">
          <div
            className={`${styles.addAllItemsButton} col-xs-12of12 col-md-offset-8of12 col-md-4of12 col-lg-offset-9of12 col-lg-3of12`}
          >
            <Button
              data-test="addAllButton"
              label={label || `Add all ${itemCount} item${itemCount === 1 ? '' : 's'} to trolley`}
              onClick={() => {
                if (onTogglePreviousOrderNotesModal) {
                  onTogglePreviousOrderNotesModal();
                } else {
                  addAllItems();
                }
              }}
              theme="primary"
              width="full"
            />
          </div>
        </div>
        <AddAllItemsErrorModal />
      </>
    );
  },
);

AddAllItemsButton.displayName = 'AddAllItems';

export default AddAllItemsButton;
